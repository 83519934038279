import * as React from 'react';
import PropType from 'prop-types';
import { graphql } from 'gatsby';

import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';
import Chip from '@mui/material/Chip';
import ToggleButton from '@mui/material/ToggleButton';

import PageListPagination from '../../components/list-pagination';
import Layout from '../../components/layout/layout';
import ProgramListHero from '../../components/program-list-hero';
import ListCard from '../../components/list-card';
import QuickLinks from '../../components/quicklinks';
import ListFilter from '../../components/list-filter';

import { getKnowledgeCentreFilters, filterKnowledge } from '../../utils/filter-util';
import NoData from '../../components/no-data';

const ToggleButtonCmp = styled(ToggleButton)(() => ({
  // fontFamily: '"Avenir Bold",  "Helvetica", "Arial", sans-serif',
  fontFamily: '"Source Sans Pro", sans-serif',
  textTransform: 'capitalize',
  fontWeight: 'bold',
  '&.Mui-selected': {
    background: '#E95525 !important',
    color: '#000000'
  },
  '&.Mui-selected::after': {
    content: '""',
    height: 0,
    width: 0,
    display: 'block',
    borderWidth: '10px 10px 10px 0px',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    position: 'absolute',
    top: '34px',
    borderColor: 'transparent transparent #E95525 transparent',
    borderStyle: 'solid',
    transform: 'rotate(0deg)',
    left: '33%'
  }
}));

function KnowledgePage({ data, location }) {
  const knowledgeData = data?.allContentfulPageKnowledge?.nodes
    .sort((a, b) => new Date(b.updatedAt_) - new Date(a.updatedAt_));

  const [knowledgeList, setKnowledgeList] = React.useState(knowledgeData);

  const [pagePaginationText, setPagePaginationText] = React.useState('');

  const [rowsPerPage, setRowsPerPage] = React.useState(6);
  const [currentpageno, setCurrentpageno] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(Math.ceil(knowledgeData.length / rowsPerPage));

  /** Filter Functionality Starts ------------------ */

  const [selectedFilters, setSelectedFilters] = React.useState([]);

  const [allButtonSelected, setAllButtonSelected] = React.useState(true);
  const onAllButtonChange = () => {
    // if selected, unselected -> show all, reset page, reset all filters
    const updatedSelectedFilters = selectedFilters.map(filter => ({
      label: filter.label,
      options: []
    }));

    setSelectedFilters(updatedSelectedFilters);

    // Set new Program List
    setKnowledgeList(filterKnowledge(knowledgeData, updatedSelectedFilters));

    // Reset Page No.
    setCurrentpageno(1);
  };

  const onFilterChange = (label, selectedItems) => {
    const updatedSelectedFilters = [...selectedFilters];
    const updatedFilterIndex = selectedFilters.findIndex(filter => filter.label === label);
    if (updatedFilterIndex === -1) {
      updatedSelectedFilters.push({
        label,
        options: selectedItems
      });
    } else {
      updatedSelectedFilters[updatedFilterIndex].options = selectedItems;
    }
    setSelectedFilters(updatedSelectedFilters);

    // Reset Page No.
    setCurrentpageno(1);

    setAllButtonSelected(false);
  };

  const removeFilter = (label, optionToBeRemoved) => {
    const updatedSelectedFilters = [...selectedFilters];
    const filterToBeUpdated = updatedSelectedFilters.find(filter => filter.label === label);
    if (filterToBeUpdated) {
      filterToBeUpdated.options = filterToBeUpdated.options.filter(option => option !== optionToBeRemoved);
    }
    setSelectedFilters(updatedSelectedFilters);

    // Set new Knowledge List
    setKnowledgeList(filterKnowledge(knowledgeData, updatedSelectedFilters));

    // Reset Page No.
    setCurrentpageno(1);
  };

  const checkForSelectedOptions = () => {
    const selectedOptions = selectedFilters.map(filter => filter.options).flat(2);
    if (selectedOptions.length === 0) {
      return true;
    }
    return false;
  };

  /** Filter Functionality Ends ------------------ */

  const rowsPerPageCallback = updatedRowsPerPage => {
    setCurrentpageno(1);
    setRowsPerPage(updatedRowsPerPage);
  };

  const updateCurrentpageno = (_, newPageNo) => {
    setCurrentpageno(newPageNo);
  };

  const setPagePagination = total => {
    let start = null;
    let end = null;
    const of = total;

    // Start number logic
    if (of === 0) {
      start = 0;
      end = 0;
    }

    if (currentpageno === 1 && of > 0) {
      start = 1;
      if (total < rowsPerPage) {
        end = total;
      } else {
        end = rowsPerPage;
      }
    }

    if (currentpageno > 1) {
      start = rowsPerPage * (currentpageno - 1) + 1;
      end = rowsPerPage * currentpageno;
      if (total < end) {
        end = total;
      }
    }

    setPagePaginationText(`Showing ${start}-${end} of ${of}`);
  };

  React.useEffect(() => {
    if (checkForSelectedOptions()) {
      setAllButtonSelected(true);
    }

    const filteredKnowledge = filterKnowledge(knowledgeData, selectedFilters)
      .sort((a, b) => new Date(b.updatedAt_) - new Date(a.updatedAt_));

    // Set new Programs list by setting index start and end
    setKnowledgeList(
      filteredKnowledge.slice(
        currentpageno === 1 ? 0 : (currentpageno - 1) * rowsPerPage,
        rowsPerPage * currentpageno
      )
    );

    // Set Total Pages Count
    setTotalPages(Math.ceil(filteredKnowledge.length / rowsPerPage));
    setPagePagination(filteredKnowledge.length);
  }, [selectedFilters, rowsPerPage, currentpageno]);

  return (
    <Layout
      location={location}
      legalFootNote={data.contentfulCompListingDetails?.legalFootNote?.legalFootNote}
    >

      {/* Knowledge Hero */}
      <ProgramListHero
        pageTitle={data.contentfulCompListingDetails.entryTitle}
        description={data.contentfulCompListingDetails?.pageDescription}
        heroImage={data?.contentfulCompListingDetails?.heroImage}
        video={data.contentfulCompListingDetails?.video?.embeddedUrl.embeddedUrl || null}
        buttonLinks={data.contentfulCompListingDetails?.buttonLinks}
      />

      {/* Knowledge Filter Section */}
      <Container>

        {/* Filter Section */}
        <Grid container spacing={1} sx={{ pt: 4, pl: 1 }}>
          <Grid item xs={12}>
            <Typography variant='h2' component='h2' sx={{ mb: 2 }}>{data?.contentfulCompListingDetails.listHeading}</Typography>
            <Typography variant='headingdividerline' />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box>
                <ToggleButtonCmp
                  sx={{ display: 'inline-block', mr: 2 }}
                  size='small'
                  value='check'
                  selected={allButtonSelected}
                  onChange={() => {
                    setAllButtonSelected(!allButtonSelected);
                    onAllButtonChange();
                  }}
                >
                  All
                </ToggleButtonCmp>
              </Box>
              <Box>
                <ListFilter filters={getKnowledgeCentreFilters(knowledgeData)} selectedFilters={selectedFilters} onChange={onFilterChange} />
              </Box>
            </Box>
            <Box sx={{ backgroundColor: '#E95525', height: 5, mt: 2 }} />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ mt: 1 }}>
              <Typography
                variant='cardcategory'
                sx={{ mr: 1 }}
              >
                {pagePaginationText}
              </Typography>
              {
                selectedFilters
                  .map(filter => filter.options
                    .map(option => (
                      <Chip
                        sx={{ mb: 0 }}
                        size='small'
                        key={option}
                        label={option}
                        variant='outlined'
                        onDelete={() => removeFilter(filter.label, option)}
                      />
                    )))
              }
            </Box>
          </Grid>
        </Grid>

        {/* Knowledge List */}
        <Grid container spacing={2} sx={{ padding: '20px 0' }}>

          {/* Knowledge List */}
          {knowledgeList && knowledgeList.length > 0 && (
            knowledgeList.map(knowledge => <ListCard details={knowledge} link={`/knowledge-center/${knowledge.slug}`} key={knowledge.contentful_id} />)
          )}

          {/* Empty Container */}
          {knowledgeList && knowledgeList.length === 0 && (
            <NoData name='Knowledge Center' />
          )}

          {/* Pagination */}
          <Grid container item justifyContent='flex-start' alignItems='center'>
            <Grid xs={12} sm={12} md={4}>
              <PageListPagination totalRows={knowledgeData.length} rowsPerPageCallback={rowsPerPageCallback} />
            </Grid>
            <Grid xs={12} sm={12} md={6} lg={4} alignItems='center' justifyContent='center' container>
              <Pagination
                count={totalPages}
                page={currentpageno}
                onChange={updateCurrentpageno}
                variant='outlined'
              />
            </Grid>
            <Grid xs={0} sm={4} />
          </Grid>
        </Grid>
      </Container>

      {/* Quick Links */}
      <Container>
        {data.contentfulCompListingDetails?.quickLinks?.title && (
          <>
            <Typography variant='h2' sx={{ fontWeight: 'bold' }}>{data.contentfulCompListingDetails?.quickLinks?.title}</Typography>
            <Typography variant='headingdividerline' />
          </>
        )}
        <Grid container spacing={1} sx={{ pt: 4, mb: 4 }}>
          {data.contentfulCompListingDetails?.quickLinks && <QuickLinks data={data.contentfulCompListingDetails?.quickLinks} /> }
        </Grid>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query KnowledgeListing {
    contentfulCompListingDetails(type: {eq: "Knowledge"}) {
      contentful_id
      entryTitle
      listHeading
      heroImage {
        gatsbyImageData(
          aspectRatio: 1.78
          jpegProgressive: true
          formats: WEBP
          resizingBehavior: SCALE
          placeholder: BLURRED
          cornerRadius: 10
        )
      }
      video {
        embeddedUrl {
          embeddedUrl
        }
      }
      pageDescription {
        raw
      }
      type
      quickLinks {
        ...quickLinks
      }
      buttonLinks {
        ...buttonLink
      }
      legalFootNote {
        legalFootNote
      }
    }
    allContentfulPageKnowledge(
      sort: {fields: updatedAt, order: DESC}
      filter: {isDisabled: {ne: true}}
    ) {
      nodes {
        contentful_id
        title
        slug
        category
        createdAt(formatString: "MMMM DD, YYYY")
        updatedAt(formatString: "MMMM DD, YYYY")
        updatedAt_: updatedAt # Timestamp
        knowledgeThumbnail {
          file {
            url
          }
          gatsbyImageData(
            aspectRatio: 1.78
            jpegProgressive: true
            formats: WEBP
            placeholder: BLURRED
            resizingBehavior: SCALE
          )
        }
      }
      totalCount
    }
  }
  fragment quickLinks on ContentfulCompQuickLinkList {
    title
    quickLink {
      title
      logo {
        file {
          url
        }
        gatsbyImageData(
          aspectRatio: 1
          jpegProgressive: true
          formats: WEBP
          placeholder: BLURRED
        )
      }
      description {
        description
      }
      actionButton {
        ...buttonLink
      }
    }
  }
  fragment buttonLink on ContentfulRanCompLink {
    link {
      ... on ContentfulInternalLink {
        openInANewTab
        entryTitle
        refPage {
          __typename
          ... on ContentfulPageProgram {
            slug
          }
          ... on ContentfulPageUtility {
            slug
          }
          ... on ContentfulPageEvent {
            slug
          }
          ... on ContentfulPageKnowledge {
            slug
          }
        }
      }
      ... on ContentfulCompExternalLink {
        title
        url {
          url
        }
        openInANewTab
        logo {
          file {
            fileName
            url
            contentType
          }
        }
      }
    }
  }
`;

KnowledgePage.propTypes = {
  location: PropType.shape.isRequired
};

export default KnowledgePage;
